<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import InputText from "primevue/inputText";
import Dropdown from "primevue/dropdown";
import { dateFormate } from "../../tool/formate";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
// import EmployerView from "../Employer.vue";
import Chip from "primevue/chip";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Toast from "primevue/toast";
import User from "../../api/pos/auth/user";
import AdminAppointment from "../../api/pos/line/appointment";
import { useI18n } from "../../i18nPlugin";
import Auth from "../../api/pos/line/auth";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/core";

const fullCalendar = ref();
const op = ref();
let authAPI = new Auth();
let admin_appointmentAPI = new AdminAppointment();

const i18n = useI18n();
const toast = useToast();
const store = useStore();

const data = reactive({
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewingAppointmentStatus,
    },
    {
      id: 2,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewedAppointmentStatus,
    },
    // {
    //   id: 3,
    //   name: i18n.$t("Appointment").AppointmentTable.StatusShow
    //     .CancelAppointmentStatus,
    // },
  ],
  date_confirm: null,
  search_date: new Date(),
  AddShiftSearch: "",
  selectShiftList: [],
  fastDialog: false,
  shift_contents: [],
  shift_content: null,
  shift_content_title: "",
  options: {
    locale: "zh-tw",
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    // initialDate:
    //   new Date().getFullYear() +
    //   "-" +
    //   (new Date().getMonth() + 1) +
    //   "-" +
    //   new Date().getDate(),
    dateClick: handleDateClick,
    customButtons: {
      prev: {
        // this overrides the prev button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.prev();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
      next: {
        // this overrides the next button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.next();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
    },
    headerToolbar: {
      left: "prev,next",
      center: "title",
      right: "dayGridMonth timeGridWeek",
      // timeGridWeek  timeGridDay
    },
    buttonText: {
      month: "月",
      week: "週",
      day: "日",
    },
    views: {
      timeGridWeek: {
        titleFormat: { year: "numeric", month: "short" },
      },
      //       timeGridWeek: {
      //     columnHeaderHtml: function (date) {
      //       return (
      //         date.toISOString().slice(0, 10).split("-")[1] +
      //         "<br>" +
      //         date.toISOString().slice(0, 10).split("-")[2] +
      //         "<br>" +
      //         weekday[date.weekday()]
      //       );
      //     },
      //   },
    },
    // eventMinHeight: "15",
    // dayHeaders: true,
    // dayMinWidth: "auto",
    // resourceAreaWidth: '0px',
    height: 1000 - 68,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: null,
  },
  appointmentFilters: {
    customer_name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  // 获取查询字符串
  const queryString = window.location.search;

  // 解析查询字符串成对象
  const params = new URLSearchParams(queryString);

  // 获取参数的值
  const liff = params.get("liff");
  const bot_id = params.get("bot_id");
  const company_id = params.get("company_id");
  data.bot_id = bot_id;
  data.company_id = company_id;
  // let check = router.currentRoute.value.path.split("/");
  // console.log(check[check.length - 1]);
  try {
    await liff.init({ liffId: liff }); // Use own liffId
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  CheckUser();
  // try {
  //   await liff.init({ liffId: "1660724561-AxDww0XB" }); // Use own liffId
  //   if (!liff.isLoggedIn()) {
  //     await liff.login({ redirectUri: window.location.href });
  //   }
  // } catch (err) {
  //   console.log(`liff.state init error ${err}`);
  // }
});

function handleDateClick(arg) {
  data.shift_content_title = arg.dateStr;
  data.shift_contents = [];
  if (data.options.events != null)
    for (let i = 0; i < data.options.events.length; i++) {
      if (
        dateFormate(data.options.events[i].start, "YYYY-MM-DD") ==
        dateFormate(arg.dateStr, "YYYY-MM-DD")
      ) {
        data.shift_contents.push(data.options.events[i]);
      }
    }
  data.AddShiftSearch = "";
  data.chooseShiftDialog = true;
  op.value.hide();
}

function getShift() {
  let tmp_ids = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp_ids.push(store.state.bouns_employer[i].id);
  }
  let start = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth(),
    1,
    0,
    0
  );
  let end = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth() + 1,
    0,
    23,
    59,
    59
  );

  admin_appointmentAPI
    .getAppointmentList(
      data.appointmentFilters.customer_name.value,
      start.toISOString(),
      end.toISOString(),
      data.appointmentFilters.status.value,
      data.company_id,
      1,
      10000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        let tmp_data = res.data.data.datas;
        let tmp = [];
        if (tmp_data != null)
          for (let i = 0; i < tmp_data.length; i++) {
            if (tmp_data[i].appointment_content != null)
              for (let j = 0; j < tmp_data[i].appointment_content.length; j++) {
                let tmp_start = new Date(
                  tmp_data[i].appointment_content[j].start_time
                );
                let tmp_end = new Date(
                  tmp_data[i].appointment_content[j].end_time
                );

                tmp.push({
                  //   id: tmp_data[i].employer_shift_relations[j].shift_id,
                  shift_id: tmp_data[i].id,
                  title: tmp_data[i].appointment_content[j].service.name,
                  //   tmp_data[i].appointment_content[j].service.name +
                  //   "|" +
                  //   tmp_data[i].appointment_content[j].employer.name +
                  //   "[號碼：" +
                  //   tmp_data[i].appointment_content[j].employer.id +
                  //   "]",
                  start: tmp_start,
                  end: tmp_end,
                  service: tmp_data[i].appointment_content[j].service.name,
                  employer_name:
                    tmp_data[i].appointment_content[j].employer.id == 0
                      ? "不指定"
                      : tmp_data[i].appointment_content[j].employer.name +
                        "(號碼：" +
                        tmp_data[i].appointment_content[j].employer.id +
                        ")",
                  customer_name: tmp_data[i].customer_name,
                  employer_id: tmp_data[i].appointment_content[j].employer_id,
                  status: tmp_data[i].status,
                  color: "#33F4FF",
                });
              }
          }
        data.options.events = tmp;
        // data.events = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function toggle(event, arg) {
  data.chooseShiftDialog = false;
  data.shift_content = arg;
  op.value.toggle(event);
}

function CheckUser() {
  authAPI.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      //   data.add_product_order.orderer_name = res.data.data.name;
      //   data.add_product_order.orderer_phone = res.data.data.phone;
      getShift();
    } else {
      Login();
    }
  });
}

const mounted = onMounted(async () => {
  getShift();
});

async function Login() {
  try {
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
    let user = await liff.getProfile();
    authAPI
      .loginFrontendUser(
        user.userId,
        user.displayName,
        "hYorHuMkC5tziGicTJoCMLQZS",
        data.bot_id
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
}
watch(
  () => data.appointmentFilters.customer_name.value,
  () => {
    getShift();
  }
);

watch(
  () => data.StatusSearch,
  () => {
    if (data.StatusSearch != null && data.StatusSearch != "") {
      data.appointmentFilters.status.value = data.StatusSearch.id;
    } else {
      data.appointmentFilters.status.value = 0;
    }
    getShift();
  }
);

watch(
  () => data.search_date,
  () => {
    getShift();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div class="p-text-left">
      <div class="p-col-12">
        <FullCalendar
          ref="fullCalendar"
          :events="data.events"
          :options="data.options"
          aria-haspopup="true"
          aria-controls="overlay_panel"
        >
          <template v-slot:eventContent="arg">
            <b
              :style="
                arg.event.extendedProps.status == 1
                  ? 'Color:red;overflow:auto'
                  : arg.event.extendedProps.status == 2
                  ? 'Color:green;overflow:auto'
                  : 'display:none'
              "
              @click="toggle($event, arg.event)"
              aria-haspopup="true"
              aria-controls="overlay_panel"
            >
              {{ dateFormate(arg.event.start, "hh:mm") }}-
              {{ dateFormate(arg.event.end, "hh:mm") }}
              {{
                arg.event.extendedProps.status == 1
                  ? arg.event.title + "(待審核)"
                  : arg.event.title
              }}</b
            >
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="data.chooseShiftDialog"
    header="當日預約"
    style="width: 600px; min-width: 375px"
  >
    日期：{{ dateFormate(data.shift_content_title, "YYYY-MM-DD") }}<br />
    <div>
      <div class="p-grid">
        <div class="p-col-4">服務項目</div>
        <div class="p-col-4">時間</div>
        <div class="p-col-4">師傅</div>
      </div>
      <div style="overflow: scroll; height: 200px">
        <div
          :key="index"
          v-for="(shift, index) in data.shift_contents"
          class="p-grid"
        >
          <div class="p-col-4">
            {{ shift.service }}
          </div>
          <div class="p-col-4">
            {{ dateFormate(shift.start, "hh:mm") }}-{{
              dateFormate(shift.end, "hh:mm")
            }}
          </div>
          <div class="p-col-4">
            {{ shift.employer_name }}
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <OverlayPanel
    ref="op"
    appendTo="body"
    :showCloseIcon="true"
    id="overlay_panel"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <h3>{{ data.shift_content.title }}</h3>
    日期：{{ dateFormate(data.shift_content.start, "YYYY-MM-DD") }}<br />
    服務項目：{{ data.shift_content.extendedProps.service }}<br />
    <!-- 客人：{{ data.shift_content.extendedProps.customer_name }}<br /> -->
    師傅：{{ data.shift_content.extendedProps.employer_name }}<br />
    開始時間：{{ dateFormate(data.shift_content.start, "hh:mm") }}<br />
    結束時間：{{ dateFormate(data.shift_content.end, "hh:mm") }}<br />
  </OverlayPanel>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
