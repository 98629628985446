import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import { defineAsyncComponent } from "vue";
import Loading from "../components/common/Loading.vue";

////////////////////////最高權限//////////////////////////////

const CompanyEmployer = defineAsyncComponent({
  loader: () => import("../views/admin/Employer.vue"),
  loadingComponent: Loading,
});

const Company = defineAsyncComponent({
  loader: () => import("../views/admin/Company.vue"),
  loadingComponent: Loading,
});

const CompanyOrganisation = defineAsyncComponent({
  loader: () => import("../views/admin/Organisation.vue"),
  loadingComponent: Loading,
});

const CompanyBill = defineAsyncComponent({
  loader: () => import("../views/admin/CompanyBill.vue"),
  loadingComponent: Loading,
});

const CompanyLimit = defineAsyncComponent({
  loader: () => import("../views/admin/CompanyLimit.vue"),
  loadingComponent: Loading,
});

const CompanyLineBot = defineAsyncComponent({
  loader: () => import("../views/admin/LineBot.vue"),
  loadingComponent: Loading,
});

const CompanyPermissions = defineAsyncComponent({
  loader: () => import("../views/admin/Permissions.vue"),
  loadingComponent: Loading,
});

const CompanyDepartment = defineAsyncComponent({
  loader: () => import("../views/admin/Department.vue"),
  loadingComponent: Loading,
});

const CompanyPosition = defineAsyncComponent({
  loader: () => import("../views/admin/Position.vue"),
  loadingComponent: Loading,
});

//////////////////////////公司員工最高權限////////////////////////////

const CompanyAdminCompanyBill = defineAsyncComponent({
  loader: () => import("../views/company_admin/CompanyBill.vue"),
  loadingComponent: Loading,
});

const CompanyAdminLineBot = defineAsyncComponent({
  loader: () => import("../views/company_admin/LineBot.vue"),
  loadingComponent: Loading,
});

const CompanyAdminOrganisation = defineAsyncComponent({
  loader: () => import("../views/company_admin/Organisation.vue"),
  loadingComponent: Loading,
});

const CompanyAdminDepartment = defineAsyncComponent({
  loader: () => import("../views/company_admin/Department.vue"),
  loadingComponent: Loading,
});

const CompanyAdminPosition = defineAsyncComponent({
  loader: () => import("../views/company_admin/Position.vue"),
  loadingComponent: Loading,
});

const CompanyAdminEmployer = defineAsyncComponent({
  loader: () => import("../views/company_admin/Employer.vue"),
  loadingComponent: Loading,
});

const CompanyAdminPermissions = defineAsyncComponent({
  loader: () => import("../views/company_admin/Permissions.vue"),
  loadingComponent: Loading,
});

const CompanyAdminCaseList = defineAsyncComponent({
  loader: () => import("../views/company_admin/CaseList.vue"),
  loadingComponent: Loading,
});

const CompanyAdminCaseProfit = defineAsyncComponent({
  loader: () => import("../views/company_admin/CaseProfit.vue"),
  loadingComponent: Loading,
});

const CompanyAdminService = defineAsyncComponent({
  loader: () => import("../views/company_admin/Service.vue"),
  loadingComponent: Loading,
});

const CompanyAdminShift = defineAsyncComponent({
  loader: () => import("../views/company_admin/Shift.vue"),
  loadingComponent: Loading,
});

const CompanyAdminAppointmentEmployer = defineAsyncComponent({
  loader: () => import("../views/company_admin/AppointmentEmployer.vue"),
  loadingComponent: Loading,
});

const CompanyAdminAppointmentLog = defineAsyncComponent({
  loader: () => import("../views/company_admin/AppointmentLog.vue"),
  loadingComponent: Loading,
});

const CompanyAdminAppointment = defineAsyncComponent({
  loader: () => import("../views/company_admin/Appointment.vue"),
  loadingComponent: Loading,
});

const CompanyAdminEmployerShift = defineAsyncComponent({
  loader: () => import("../views/company_admin/EmployerShift.vue"),
  loadingComponent: Loading,
});

const CompanyAdminLineMsg = defineAsyncComponent({
  loader: () => import("../views/company_admin/SendMsg.vue"),
  loadingComponent: Loading,
});

const CompanyAdminLineUser = defineAsyncComponent({
  loader: () => import("../views/company_admin/LineUser.vue"),
  loadingComponent: Loading,
});

//////////////////////////////////////////////////////
const AppointmentLogCustomer = defineAsyncComponent({
  loader: () => import("../views/line_user/AppointmentLogCustomer.vue"),
  loadingComponent: Loading,
});
const AppointmentCustomer = defineAsyncComponent({
  loader: () => import("../views/line_user/AppointmentCustomer.vue"),
  loadingComponent: Loading,
});

const Home = defineAsyncComponent({
  loader: () => import("../views/Home.vue"),
  loadingComponent: Loading,
});

const routes = [
  {
    path: "/company-admin/booking/service",
    name: "CompanyAdminService",
    component: CompanyAdminService,
  },
  {
    path: "/company-admin/booking/employer",
    name: "CompanyAdminAppointmentEmployer",
    component: CompanyAdminAppointmentEmployer,
  },
  {
    path: "/company-admin/booking/shift",
    name: "CompanyAdminShift",
    component: CompanyAdminShift,
  },
  {
    path: "/company-admin/booking/appointmentlog",
    name: "CompanyAdminAppointmentLog",
    component: CompanyAdminAppointmentLog,
  },
  {
    path: "/company-admin/booking/appointment",
    name: "CompanyAdminAppointment",
    component: CompanyAdminAppointment,
  },
  {
    path: "/company-admin/booking/employer-shift",
    name: "CompanyAdminEmployerShift",
    component: CompanyAdminEmployerShift,
  },
  {
    path: "/company-admin/booking/line-msg",
    name: "CompanyAdminLineMsg",
    component: CompanyAdminLineMsg,
  },
  {
    path: "/company-admin/booking/line-user",
    name: "CompanyAdminLineUser",
    component: CompanyAdminLineUser,
  },
  {
    path: "/company-admin/case",
    name: "CompanyAdminCaseList",
    component: CompanyAdminCaseList,
    children: [
      {
        path: "content", // url= shop/cart
        component: CompanyAdminCaseList,
        // meta: { requiresAuth: false },
      },
      {
        path: "add", // url= shop/cart
        component: CompanyAdminCaseList,
        // meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/company-admin/case-profit",
    name: "CompanyAdminCaseProfit",
    component: CompanyAdminCaseProfit,
  },
  {
    path: "/company-admin/permissions",
    name: "CompanyAdminPermissions",
    component: CompanyAdminPermissions,
  },
  {
    path: "/company-admin/:company_id",
    name: "CompanyAdminEmployer",
    component: CompanyAdminEmployer,
  },
  {
    path: "/company-admin",
    name: "CompanyAdminEmployer",
    component: CompanyAdminEmployer,
  },
  {
    path: "/company-admin/position",
    name: "CompanyAdminPosition",
    component: CompanyAdminPosition,
  },
  {
    path: "/company-admin/department",
    name: "CompanyAdminDepartment",
    component: CompanyAdminDepartment,
  },
  {
    path: "/company-admin/organisation",
    name: "CompanyAdminOrganisation",
    component: CompanyAdminOrganisation,
  },
  {
    path: "/company-admin/bill",
    name: "CompanyAdminCompanyBill",
    component: CompanyAdminCompanyBill,
  },
  {
    path: "/company-admin/line-bot",
    name: "CompanyAdminLineBot",
    component: CompanyAdminLineBot,
  },
  {
    path: "/_1231admin/line-bot",
    name: "LineBot",
    component: CompanyLineBot,
  },
  {
    path: "/_1231admin/limit",
    name: "CompanyLimit",
    component: CompanyLimit,
  },
  {
    path: "/_1231admin/bill",
    name: "CompanyBill",
    component: CompanyBill,
  },
  {
    path: "/_1231admin/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/_1231admin/organisation",
    name: "Organisation",
    component: CompanyOrganisation,
  },
  {
    path: "/_1231admin/department",
    name: "Department",
    component: CompanyDepartment,
  },
  {
    path: "/_1231admin/position",
    name: "Position",
    component: CompanyPosition,
  },
  {
    path: "/_1231admin/permissions",
    name: "Permissions",
    component: CompanyPermissions,
  },
  // {
  //   path: "/admin/admin",
  //   name: "CompanyAdmin",
  //   component: CompanyAdmin,
  // },
  {
    path: "/_1231admin",
    name: "CompanyEmployer",
    component: CompanyEmployer,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/appointmentlog-customer",
    name: "AppointmentLogCustomer",
    component: AppointmentLogCustomer,
  },
  {
    path: "/appointment-customer",
    name: "AppointmentCustomer",
    component: AppointmentCustomer,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
