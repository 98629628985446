import { createStore } from "vuex";
// import { app } from "../main"; //匯入我們的Vue Instance

export default createStore({
  state: {
    lang: "cn",
    admin_user: null,
    company_admin_user: null,
    company_user: null,
    user: null,
    employer: null,
    shop: [],
    shop_already: [],
    employer_search: "employer",
    bouns_employer: [],
    shift_search: "shift",
    appointment_list: [],
  },
  mutations: {
    set_company_admin_user(state, company_admin_user) {
      state.company_admin_user = company_admin_user;
    },
    set_company_user(state, company_user) {
      state.company_user = company_user;
    },
    set_admin_user(state, admin_user) {
      state.admin_user = admin_user;
    },
    set_appointment_list(state, appointment_list) {
      state.appointment_list = appointment_list;
    },
    set_shift_search(state, shift_search) {
      state.shift_search = shift_search;
    },
    set_bouns_employer(state, bouns_employer) {
      state.bouns_employer = bouns_employer;
    },
    set_lang(state, value) {
      state.lang = value;
    },
    set_user(state, user) {
      state.user = user;
    },
    set_employer(state, employer) {
      state.employer = employer;
    },
    set_shop(state, shop) {
      state.shop = shop;
    },
    set_shop_already(state, shop_already) {
      state.shop_already = shop_already;
    },
    set_employer_search(state, employer_search) {
      state.employer_search = employer_search;
    },
  },
  actions: {},
  modules: {},
});
