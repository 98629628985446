<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import { dateFormate } from "../../tool/formate";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Appointment from "../../api/pos/line/appointment";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import firebase from "firebase";
import router from "../../router";
import Auth from "../../api/pos/line/auth";
import Service from "../../api/pos/line/service";
import Employer from "../../api/pos/line/employer";

let appointmentAPI = new Appointment();
let serviceAPI = new Service();
let employerAPI = new Employer();
let authAPI = new Auth();

const store = useStore();

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  teacher_choose_date: null,
  teacher_choose_time: "",
  teacher_free: [],
  employerSearchTeacher: null,
  serviceSearchTeacher: null,
  selectServiceListTeacher: [],
  show: 0,
  diff: [],
  today: new Date(),
  check_bt: false,
  appointment_content: [],
  expandedRows: [],
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewingAppointmentStatus,
    },
    {
      id: 2,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewedAppointmentStatus,
    },
    {
      id: 3,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .CancelAppointmentStatus,
    },
  ],
  selectServiceList: [],
  selectEmployerList: [],
  add_appointment: {
    id: "",
    name: "",
    remark: "",
    start_time: "",
    end_time: "",
    customer_phone: "",
  },
});
const beforemounted = onBeforeMount(async () => {
  // 获取查询字符串
  const queryString = window.location.search;

  // 解析查询字符串成对象
  const params = new URLSearchParams(queryString);
  const liff_id = params.get("liff");
  const bot_id = params.get("bot_id");
  const company_id = params.get("company_id");
  data.bot_id = bot_id;
  data.company_id = company_id;
  // let check = router.currentRoute.value.path.split("/");
  // console.log(check[check.length - 1]);
  try {
    await liff.init({ liffId: liff_id }); // Use own liffId
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  CheckUser();
});
const mounted = onMounted(async () => {
  addAppointment();
  getServiceSelectList(1, "");
});

function getDatesForTwoMonths() {
  var startDate = new Date(); // 获取当前日期
  var dates = [];
  let m = 0;
  // 循环生成两个月的日期
  for (var i = 0; i < 60; i++) {
    var date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);

    while (date.getMonth() === startDate.getMonth() + i) {
      dates.push(new Date(date).toISOString()); // 将日期对象添加到数组中
      date.setDate(date.getDate() + 1); // 递增日期
    }
    m++;
    if (m == 2) break;
  }

  return dates;
}

function getEmployerAppointmentList(id) {
  let tmp = new Date();
  let start_time = new Date(
    tmp.getFullYear(),
    tmp.getMonth(),
    tmp.getDate() + 1,
    0
  );
  let end_time = new Date(tmp.getFullYear(), tmp.getMonth() + 2, 1, 0);
  employerAPI
    .getEmployerAppointmentList(
      id,
      start_time.toISOString(),
      end_time.toISOString(),
      Number(data.company_id)
    )
    .then(async (res) => {
      if (res.data.error_code == 0) {
        let check = res.data.data.AppointmentTime;
        await check.sort(function (a, b) {
          // 按照 name 属性进行升序排序
          var nameA = a.StartTime;
          var nameB = b.StartTime;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        // for (let i = 0; i < res.data.data.AppointmentTime.length; i++) {}
        data.teacher_appointment = check;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
/////////待解決
function getEmployerShipRelationsTeacher(id) {
  let tmp = new Date();
  let start_time = new Date(tmp.getFullYear(), tmp.getMonth(), 1, 0);
  let end_time = new Date(tmp.getFullYear(), tmp.getMonth() + 2, 1, 0);
  data.diff = [];
  employerAPI
    .getEmployerShipRelations(
      [id],
      start_time.toISOString(),
      end_time.toISOString(),
      data.company_id
    )
    .then(async (res) => {
      if (res.data.error_code == 0) {
        let check = [];
        let all = await getDatesForTwoMonths();
        data.teacher_shit = res.data.data.datas;
        for (let i = 0; i < res.data.data.datas.length; i++) {
          for (
            let j = 0;
            j < res.data.data.datas[i].employer_shift_relations.length;
            j++
          ) {
            if (
              res.data.data.datas[i].employer_shift_relations[j].status == 2 &&
              res.data.data.datas[i].employer_shift_relations[j].stage == 2 &&
              !(await check.includes(
                new Date(
                  res.data.data.datas[i].employer_shift_relations[j].date
                ).toISOString()
              ))
            ) {
              await check.push(
                new Date(
                  res.data.data.datas[i].employer_shift_relations[j].date
                ).toISOString()
              );
            }
          }
        }
        check.sort();
        let diff = [];
        for (let i = 0; i < all.length; i++) {
          if (!check.includes(all[i])) {
            await diff.push(all[i]);
          }
        }
        for (let i = 0; i < diff.length; i++) {
          await data.diff.push(new Date(diff[i]));
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
////////待解決
function getEmployerShipRelations() {
  let tmp = new Date();
  let start_time = new Date(tmp.getFullYear(), tmp.getMonth(), 1, 0);
  let end_time = new Date(tmp.getFullYear(), tmp.getMonth() + 2, 1, 0);
  data.diff = [];
  employerAPI
    .getEmployerShipRelations(
      [],
      start_time.toISOString(),
      end_time.toISOString(),
      data.company_id
    )
    .then(async (res) => {
      if (res.data.error_code == 0) {
        let check = [];
        let all = await getDatesForTwoMonths();
        for (let i = 0; i < res.data.data.datas.length; i++) {
          for (
            let j = 0;
            j < res.data.data.datas[i].employer_shift_relations.length;
            j++
          ) {
            if (
              res.data.data.datas[i].employer_shift_relations[j].status == 2 &&
              res.data.data.datas[i].employer_shift_relations[j].stage == 2 &&
              !(await check.includes(
                new Date(
                  res.data.data.datas[i].employer_shift_relations[j].date
                ).toISOString()
              ))
            ) {
              await check.push(
                new Date(
                  res.data.data.datas[i].employer_shift_relations[j].date
                ).toISOString()
              );
            }
          }
        }
        check.sort();
        let diff = [];
        for (let i = 0; i < all.length; i++) {
          if (!check.includes(all[i])) {
            await diff.push(all[i]);
          }
        }
        for (let i = 0; i < diff.length; i++) {
          await data.diff.push(new Date(diff[i]));
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function CheckUser() {
  authAPI.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      data.add_appointment.customer_name = res.data.data.name;
      data.add_appointment.customer_phone = res.data.data.phone;
    } else {
      Login();
    }
  });
}
async function Login() {
  try {
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
    let user = await liff.getProfile();
    authAPI
      .loginFrontendUser(
        user.userId,
        user.displayName,
        "jYPaPoEhRUXr4PM3Xa9qWjea7C8b6Kx",
        Number(data.bot_id)
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
}

function getServiceSelectList(type, employer_id) {
  serviceAPI
    .getServiceSelectList("", data.company_id, employer_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectServiceListTeacher = [];
        for (let i = 0; i < res.data.data.datas.length; i++) {
          if (type == 1) {
            data.selectServiceList.push(res.data.data.datas[i]);
          } else {
            data.selectServiceListTeacher.push(res.data.data.datas[i]);
          }
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function getEmployerSelectListTeacher() {
  employerAPI.getEmployerSelectList("", data.company_id).then((res) => {
    if (res.data.error_code == 0) {
      for (let i = 0; i < res.data.data.datas.length; i++) {
        if (res.data.data.datas[i].status == 2)
          data.selectEmployerList.push({
            id: res.data.data.datas[i].id,
            name:
              res.data.data.datas[i].name +
              "(編號：" +
              res.data.data.datas[i].id +
              ")",
          });
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerSelectList() {
  employerAPI
    .getEmployerFreeSelectList(
      data.add_start_time != "" && data.add_start_time != null
        ? data.add_start_time.toISOString()
        : "",
      data.add_end_time != "" && data.add_end_time != null
        ? data.add_end_time.toISOString()
        : "",
      data.add_service_id,
      data.company_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectEmployerList = res.data.data.datas;
        for (let i = 0; i < data.selectEmployerList.length; i++) {
          if (data.selectEmployerList[i].id != 0)
            data.selectEmployerList[i].name =
              data.selectEmployerList[i].name +
              "(編號：" +
              data.selectEmployerList[i].id +
              ")";
        }
        if (res.data.data.datas != null)
          if (res.data.data.datas.length != 0)
            data.selectEmployerList.unshift({
              auth_method: 0,
              created_at: "",
              delete_service_ids: null,
              delete_shift_dates: null,
              delete_shift_ids: null,
              english_name: "LEYU",
              id: 0,
              image: "",
              image_url: "",
              name: "不指定",
              new_service_ids: null,
              new_shifts: null,
              organisation_id: 0,
              organisation_name: "",
              password: "",
              position_name: "test321",
              role: 0,
              salary: "",
              services: null,
              shifts: null,
              status: 0,
              updated_at: "",
              username: "test",
            });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddAppointment() {
  data.check_bt = true;
  if (data.show == 2) {
    data.appointment_content = [];
    data.add_appointment.start_time = data.teacher_choose_time;

    data.appointment_content.push({
      employer_id: data.employerSearchTeacher.id,
      service_id: data.serviceSearchTeacher.id,
      service_name: data.serviceSearchTeacher.name,
      employer_name: data.employerSearchTeacher.name,
      remark: "",
      start_time: data.teacher_choose_time.toISOString(),
      company_id: Number(data.company_id),
    });
  }
  appointmentAPI
    .addAppointment(
      data.add_appointment.customer_phone,
      data.add_appointment.customer_name,
      2,
      1,
      data.add_appointment.start_time.toISOString(),
      data.add_appointment.remark,
      data.appointment_content,
      Number(data.company_id)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Appointment").AddSuccess,
          life: 3000,
        });
        CheckUser();
        addAppointment();
        data.check_bt = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        data.check_bt = false;
      }
    });

  ChooseWay(data.show);
}

function addAppointment() {
  data.add_start_time = "";
  data.add_end_time = "";
  data.add_service_id = 0;
  data.appointment_content = [];
  data.add_appointment = {
    id: "",
    name: "",
    remark: "",
    customer_phone: "",
    start_time: "",
    end_time: "",
  };
  data.selectEmployerList = [];
  data.employerSearchTeacher = "";
  data.teacher_choose_date = null;
  data.teacher_choose_time = "";
  data.serviceSearchTeacher = "";
}

watch(
  () => data.serviceSearch,
  () => {
    if (data.serviceSearch != null && data.serviceSearch != "") {
      let tmp_end = new Date(data.add_start_time);
      tmp_end.setTime(
        tmp_end.setMinutes(tmp_end.getMinutes() + data.serviceSearch.duration)
      );
      //   tmp_end.setTime(tmp_end.setMinutes(tmp_end.getMinutes() + 30));
      data.add_end_time = tmp_end;
      data.add_service_id = data.serviceSearch.id;
      getEmployerSelectList();
    } else {
      data.add_end_time = "";
      data.add_service_id = 0;
      data.selectEmployerList = [];
    }
  }
);

watch(
  () => data.add_appointment.start_time,
  () => {
    // data.add_start_time = data.add_appointment.start_time;
    if (
      data.add_appointment.start_time != null &&
      data.add_appointment.start_time != ""
    ) {
      data.add_start_time = data.add_appointment.start_time;
      data.add_start_time.setSeconds(0);
      data.add_start_time.setMilliseconds(0);
    } else {
      data.add_start_time = "";
    }
    data.serviceSearch = "";
    data.selectEmployerList = [];
    data.appointment_content = [];
  }
);

watch(
  () => data.teacher_choose_date,
  async () => {
    data.teacher_choose_time = "";
    if (data.teacher_choose_date != null) {
      data.teacher_free = [];
      let tmp_appointment = [];
      console.log(data.teacher_appointment);
      for (let i = 0; i < data.teacher_appointment.length; i++) {
        data.teacher_appointment[i];
        let check_date = new Date(data.teacher_appointment[i].StartTime);
        if (
          check_date.getFullYear() == data.teacher_choose_date.getFullYear() &&
          check_date.getMonth() == data.teacher_choose_date.getMonth() &&
          check_date.getDate() == data.teacher_choose_date.getDate()
        ) {
          await tmp_appointment.push(data.teacher_appointment[i]);
        }
      }
      for (
        let i = 0;
        i < data.teacher_shit[0].employer_shift_relations.length;
        i++
      ) {
        let check_date = new Date(
          data.teacher_shit[0].employer_shift_relations[i].date
        );
        if (
          check_date.getFullYear() == data.teacher_choose_date.getFullYear() &&
          check_date.getMonth() == data.teacher_choose_date.getMonth() &&
          check_date.getDate() == data.teacher_choose_date.getDate()
        ) {
          let tmp_tmp_start = new Date(
            data.teacher_shit[0].employer_shift_relations[i].shift.start_time
          );
          let tmp_tmp_end = new Date(
            data.teacher_shit[0].employer_shift_relations[i].shift.end_time
          );
          let tmp_start = new Date(
            check_date.getFullYear(),
            check_date.getMonth(),
            check_date.getDate(),
            tmp_tmp_start.getHours(),
            tmp_tmp_start.getMinutes(),
            0,
            0
          );
          let tmp_end = new Date(
            check_date.getFullYear(),
            check_date.getMonth(),
            check_date.getDate(),
            tmp_tmp_end.getHours(),
            tmp_tmp_end.getMinutes(),
            0,
            0
          );
          while (true) {
            if (
              tmp_start.getHours() == tmp_end.getHours() &&
              tmp_start.getMinutes() == tmp_end.getMinutes()
            ) {
              break;
            }
            let check = true;
            let tmp_check_end = await addMinutesToDate(tmp_start, 30);

            for (let j = 0; j < tmp_appointment.length; j++) {
              // 已預約開始時間>=開始時間＆結束時間<=已預約結束時間
              // 已預約開始時間<開始時間&開始時間<已預約結束時間
              // 已預約開始時間<結束時間&結束時間<已預約開始時間

              let check_start = new Date(tmp_appointment[j].StartTime);
              let check_end = new Date(tmp_appointment[j].EndTime);

              let check_start_tmp = Math.floor(
                new Date(tmp_appointment[j].StartTime).getTime() / 60000
              );
              let check_end_tmp = Math.floor(
                new Date(tmp_appointment[j].EndTime).getTime() / 60000
              );

              let tmp_check_check_start = Math.floor(
                tmp_start.getTime() / 60000
              );
              let tmp_check_check_end = Math.floor(
                tmp_check_end.getTime() / 60000
              );

              // console.log("------------");
              // console.log(tmp_start);
              // console.log(tmp_check_end);
              // console.log(check_start);
              // console.log(check_end);
              // console.log("------------");

              // console.log(".........");
              // console.log(Math.floor(tmp_start.getTime() / 60000));
              // console.log(Math.floor(tmp_check_end.getTime() / 60000));
              // console.log(Math.floor(check_start.getTime() / 60000));
              // console.log(Math.floor(check_end.getTime() / 60000));
              // console.log(".........");

              if (
                (check_start_tmp <= tmp_check_check_start &&
                  tmp_check_check_end <= check_end_tmp) ||
                (check_start_tmp < tmp_check_check_start &&
                  tmp_check_check_start < check_end_tmp) ||
                (check_start_tmp < tmp_check_check_end &&
                  tmp_check_check_end < check_end_tmp)
              ) {
                check = false;
              }
            }

            if (check) {
              data.teacher_free.push(tmp_start);
              // console.log("*****可以****");
              // console.log(tmp_start);
              // console.log(tmp_check_end);
              // console.log("*********");
            }

            // tmp_start = addMinutesToDate(tmp_start, 30);
            tmp_start = tmp_check_end;
            // console.log(tmp_start);
          }
          console.log("------------");
          console.log(tmp_appointment);
        }
      }
    } else {
    }
  }
);
function addMinutesToDate(date, minutes) {
  if (!(date instanceof Date) || isNaN(minutes)) {
    throw new Error("Invalid date or minutes.");
  }

  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + 30);
  // newDate.setTime(newDate.getTime() + minutes * 60 * 1000);
  // newDate.setSeconds(0, 0);
  return newDate;
}

watch(
  () => data.employerSearchTeacher,
  () => {
    data.teacher_choose_time = "";
    data.teacher_choose_date = null;
    data.serviceSearchTeacher = "";
    if (
      data.employerSearchTeacher != null &&
      data.employerSearchTeacher != ""
    ) {
      getEmployerShipRelationsTeacher(data.employerSearchTeacher.id);
      getEmployerAppointmentList(data.employerSearchTeacher.id);
      getServiceSelectList(2, data.employerSearchTeacher.id);
    }
  }
);

function AddAppointmentContent() {
  data.add_start_time.setSeconds(0);
  data.add_start_time.setMilliseconds(0);
  data.add_end_time.setSeconds(0);
  data.add_end_time.setMilliseconds(0);
  data.appointment_content.push({
    employer_id: data.employerSearch.id,
    service_id: data.add_service_id,
    service_name: data.serviceSearch.name,
    employer_name: data.employerSearch.name,
    remark: "",
    start_time: data.add_start_time.toISOString(),
    company_id: Number(data.company_id),
  });
  data.add_start_time = data.add_end_time;
  data.serviceSearch = "";
  data.selectEmployerList = [];
  data.employerSearch = "";
}
function removeAppoimentContent() {
  data.add_start_time = new Date(
    data.appointment_content[data.appointment_content.length - 1].start_time
  );
  data.appointment_content.splice(-1, 1);
  data.serviceSearch = "";
  data.selectEmployerList = [];
  data.employerSearch = "";
}
function Initial() {
  data.show = 0;
  data.add_appointment = {
    id: "",
    name: "",
    remark: "",
    start_time: "",
    end_time: "",
    customer_phone: "",
  };
  CheckUser();
  data.appointment_content = [];
  data.selectEmployerList = [];
  data.employerSearchTeacher = "";
  data.teacher_choose_date = null;
  data.teacher_choose_time = "";
  data.serviceSearchTeacher = "";
}

function ChooseWay(show) {
  data.show = show;
  if (data.show == 1) {
    getEmployerShipRelations();
  } else if (data.show == 2) {
    getEmployerSelectListTeacher();
  }
}
</script>

<template>
  <Toast />
  <div
    v-if="data.show == 0"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    "
  >
    <div class="p-grid" style="width: 100%">
      <div
        class="p-col-12"
        style="display: flex; justify-content: center; align-items: center"
      >
        <div
          style="
            width: 95%;
            border: 1px solid black;
            padding: 1px;
            border-radius: 10px;
            display: flex;
            height: 40vh;
            justify-content: center;
            align-items: center;
          "
          @click="ChooseWay(1)"
        >
          <h1>一般預約</h1>
        </div>
      </div>
      <div
        class="p-col-12"
        style="display: flex; justify-content: center; align-items: center"
      >
        <div
          style="
            width: 95%;
            border: 1px solid black;
            padding: 1px;
            border-radius: 10px;
            display: flex;
            height: 40vh;
            justify-content: center;
            align-items: center;
          "
          @click="ChooseWay(2)"
        >
          <h1>指名預約</h1>
        </div>
      </div>
    </div>
  </div>
  <div v-if="data.show == 1" class="p-mt-5 p-fluid">
    <!-- 新增視窗 -->
    <!-- <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Appointment').AddAppointment.Title"
      :modal="true"
      class="p-fluid"
    > -->
    <div @click="Initial" style="margin-bottom: -10px; text-align: left">
      <i class="pi pi-replay"></i>上一頁
    </div>
    <h2 style="margin-top: -25px">{{ i18n.$t("Appointment").Title }}</h2>
    <h5 class="p-text-left" style="margin-top: -10px">
      (如找不到您所需預約時段，請LINE留言預約)
    </h5>
    <div class="p-field p-text-left">
      <label for="name"
        >{{
          i18n.$t("Appointment").AppointmentTable.CustomerName.Show
        }}(請填真實姓名）</label
      >
      <InputText
        id="name"
        v-model.trim="data.add_appointment.customer_name"
        required="true"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.CustomerPhone.Show
      }}</label>
      <InputText
        id="name"
        v-model.trim="data.add_appointment.customer_phone"
        required="true"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.StartTime.Show
      }}</label>
      <Calendar
        :min-date="new Date(data.today.getTime() + 24 * 60 * 60 * 1000)"
        :max-date="
          new Date(
            new Date(
              data.today.getFullYear(),
              data.today.getMonth() + 2,
              1
            ).getTime() - 1
          )
        "
        inputId="time12"
        :disabledDates="data.diff"
        v-model="data.add_appointment.start_time"
        :timeOnly="false"
        :showTime="true"
        :stepMinute="30"
        hourFormat="24"
      />
    </div>
    <div class="p-field">
      <!-- <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.ClassShow
        }}</label> -->
      <div class="p-grid" style="background: gray; color: white">
        <div class="p-col-3">時間</div>
        <div class="p-col-3">課程</div>
        <div class="p-col-4">老師</div>
        <div class="p-col-2"></div>
      </div>
      <div style="height: 170px; overflow: scroll">
        <div
          :key="index"
          v-for="(item, index) in data.appointment_content"
          class="p-grid p-mt-1"
          style="border-bottom: 1px solid"
        >
          <div class="p-col-3" style="padding-top: 15px">
            {{ dateFormate(item.start_time, "hh:mm") }}
          </div>
          <div class="p-col-3" style="padding-top: 15px">
            {{ item.service_name }}
          </div>
          <div class="p-col-4" style="padding-top: 15px">
            {{ item.employer_name }}
          </div>
          <div class="p-col-2">
            <Button
              v-if="index + 1 == data.appointment_content.length"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger"
              @click="removeAppoimentContent"
            />
          </div>
        </div>
      </div>
      <div class="p-grid p-mt-1">
        <div class="p-col-3">
          <Calendar
            inputId="time12"
            v-model="data.add_start_time"
            :timeOnly="true"
            hourFormat="24"
            disabled
          />
        </div>
        <div class="p-col-3">
          <Dropdown
            v-model="data.serviceSearch"
            :options="data.selectServiceList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Service').Title"
            :showClear="true"
            :disabled="data.add_start_time == '' || data.add_start_time == null"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-4">
          <Dropdown
            v-model="data.employerSearch"
            :options="data.selectEmployerList"
            optionLabel="name"
            :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <!-- <img :src="slotProps.value.image_url" /> -->
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-2">
          <Button
            :disabled="data.employerSearch == '' || data.employerSearch == null"
            icon="pi pi-check"
            class="p-button-rounded p-button-info"
            @click="AddAppointmentContent"
          />
        </div>
      </div>
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AddAppointment.Remark
      }}</label>
      <Textarea
        id="remark"
        v-model="data.add_appointment.remark"
        required="true"
        rows="3"
        cols="20"
      />
    </div>
    <div>
      <Button
        :disabled="
          data.appointment_content.length == 0 ||
          data.add_appointment.customer_phone == '' ||
          data.add_appointment.customer_name == '' ||
          data.check_bt
        "
        :label="i18n.$t('Appointment').Save"
        class="p-button-secondary"
        @click="saveAddAppointment"
      />
    </div>
  </div>
  <div v-if="data.show == 2" class="p-mt-5 p-fluid">
    <div @click="Initial" style="margin-bottom: -10px; text-align: left">
      <i class="pi pi-replay"></i>上一頁
    </div>
    <h2 style="margin-top: -25px">{{ i18n.$t("Appointment").TitleTeacher }}</h2>
    <h5 class="p-text-left" style="margin-top: -10px">
      (如找不到您所需預約時段，請LINE留言預約)
    </h5>
    <div class="p-field p-text-left">
      <label for="name"
        >{{
          i18n.$t("Appointment").AppointmentTable.CustomerName.Show
        }}(請填真實姓名）</label
      >
      <InputText
        id="name"
        v-model.trim="data.add_appointment.customer_name"
        required="true"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.CustomerPhone.Show
      }}</label>
      <InputText
        id="name"
        v-model.trim="data.add_appointment.customer_phone"
        required="true"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.Teacher.Show
      }}</label>
      <Dropdown
        v-model="data.employerSearchTeacher"
        :options="data.selectEmployerList"
        optionLabel="name"
        :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <!-- <img :src="slotProps.value.image_url" /> -->
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.StartDate.Show
      }}</label>
      <Calendar
        :min-date="new Date(data.today.getTime() + 24 * 60 * 60 * 1000)"
        :max-date="
          new Date(
            new Date(
              data.today.getFullYear(),
              data.today.getMonth() + 2,
              1
            ).getTime() - 1
          )
        "
        inputId="time12"
        :disabledDates="data.diff"
        v-model="data.teacher_choose_date"
        :disabled="
          data.employerSearchTeacher == '' || data.employerSearchTeacher == null
        "
        showButtonBar
        :showTime="false"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AppointmentTable.StartTime.Show
      }}</label
      ><br />
      <Button
        v-if="
          data.teacher_choose_time == '' && data.teacher_choose_date != null
        "
        icon="pi pi-calendar"
        class="p-button-rounded p-button-success p-button-sm"
        @click="data.addDialog = true"
      />
      <span
        v-if="data.teacher_choose_time != ''"
        class="p-mr-2 p-ml-1"
        style="font-size: 18px; line-height: 37.31px"
      >
        {{ dateFormate(data.teacher_choose_time, "hh:mm") }}
      </span>
      <Button
        v-if="data.teacher_choose_time != ''"
        icon="pi pi-trash"
        class="p-button-rounded p-button-danger p-button-sm"
        @click="data.teacher_choose_time = ''"
      />
    </div>
    <div class="p-field p-text-left">
      <label for="name"
        >{{ i18n.$t("Appointment").AppointmentTable.Service.Show }}
        <!-- <span style="font-size: 10px; color: red; font-weight: 800">
          (前30分鐘為指定老師，超過30分鐘為組合課程依預約情況調整)</span
        >-->
      </label>
      <Dropdown
        v-model="data.serviceSearchTeacher"
        :options="data.selectServiceListTeacher"
        optionLabel="name"
        :filter="false"
        :placeholder="i18n.$t('Service').Title"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>

    <div class="p-field p-text-left">
      <label for="name">{{
        i18n.$t("Appointment").AddAppointment.Remark
      }}</label>
      <Textarea
        id="remark"
        v-model="data.add_appointment.remark"
        required="true"
        rows="3"
        cols="20"
      />
    </div>
    <div>
      <Button
        :disabled="
          data.teacher_choose_time == '' ||
          data.serviceSearchTeacher == null ||
          data.serviceSearchTeacher == '' ||
          data.add_appointment.customer_phone == '' ||
          data.add_appointment.customer_name == '' ||
          data.check_bt
        "
        :label="i18n.$t('Appointment').Save"
        class="p-button-secondary"
        @click="saveAddAppointment"
      />
    </div>
  </div>
  <Dialog
    v-model:visible="data.addDialog"
    :style="{ width: '450px', minWidth: '350px' }"
    header="預約時間"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <!-- <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.ClassShow
        }}</label> -->
      <div class="p-grid" style="background: gray; color: white">
        <div class="p-col-4">日期</div>
        <div class="p-col-6">預約時間</div>
        <div class="p-col-2"></div>
      </div>
      <div style="height: 150px; overflow-y: scroll; overflow-x: hidden">
        <div
          :key="index"
          v-for="(item, index) in data.teacher_free"
          class="p-grid p-mt-1"
          style="border-bottom: 1px solid"
        >
          <div class="p-col-4" style="padding-top: 15px">
            {{ dateFormate(item, "YYYY-MM-DD") }}
          </div>
          <div class="p-col-6" style="padding-top: 15px">
            {{ dateFormate(item, "hh:mm") }}
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-check"
              class="p-button-rounded p-button-info"
              @click="
                () => {
                  data.addDialog = false;
                  data.teacher_choose_time = item;
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
