import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Service {
  getServiceSelectList(name, company_id, employer_id) {
    return axios.get(
      `/line/service/select-list`,
      {
        params: {
          name: name,
          company_id: company_id,
          employer_id: employer_id,
        },
      },
      config
    );
  }
}
