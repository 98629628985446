<script setup>
import PanelMenu from "primevue/panelmenu";
import Dropdown from "primevue/dropdown";
import { ref, reactive, onMounted } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Toolbar from "primevue/toolbar";
import router from "../../router";
import cookie from "../../cookie";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import logo from "../../assets/leyu-logo-w.png";
import User from "../../api/pos/auth/user";
import firebase from "firebase";
import { useToast } from "primevue/usetoast";

const toast = useToast();

let userApi = new User();

let cookieSetting = new cookie();
const i18n = useI18n();
const store = useStore();
defineProps({
  //   video_stream: String,
});

const mounted = onMounted(async () => {
  if (store.state.company_admin_user != null) {
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_booking_serviceList"
      ) == -1 &&
      store.state.company_admin_user.permissions.indexOf(
        "company_booking_appointmentList"
      ) == -1
    ) {
      data.items = await data.items.filter(
        (item) => item.label !== i18n.$t("CompanyAdminNavBar").AllBooking.Label
      );
    }

    let tmp_booking = await data.items.filter(
      (item) => item.label == i18n.$t("CompanyAdminNavBar").AllBooking.Label
    );

    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_booking_serviceList"
      ) == -1
    ) {
      tmp_booking = await tmp_booking.filter(
        (item) => item.label !== i18n.$t("CompanyAdminNavBar").Service.Label
      );
    }

    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_organisationList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").Organisation.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_departmentList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").Department.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_positionList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").Position.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_employerList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").Employer.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_companyBillList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").CompanyBill.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_lineBotList"
      ) == -1
    ) {
      data.items[0].items = await data.items[0].items.filter(
        (item_content) =>
          item_content.to != i18n.$t("CompanyAdminNavBar").LineBot.Link
      );
    }
    if (
      store.state.company_admin_user.permissions.indexOf(
        "company_case_noteList"
      ) == -1 ||
      store.state.company_admin_user.permissions.indexOf(
        "company_case_contentList"
      ) == -1
    ) {
      data.items = await data.items.filter(
        (item) => item.label !== i18n.$t("CompanyAdminNavBar").AllCase.Label
      );
    }
  }
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      if (res.data.data.role == 1) {
        store.commit("set_admin_user", res.data.data);
      } else if (res.data.data.role == 2) {
        store.commit("set_company_admin_user", res.data.data);
      } else if (res.data.data.role == 3) {
        store.commit("set_company_user", res.data.data);
      }
    } else {
      if (router.currentRoute.value.path.indexOf("/_1231admin") != -1) {
        router.push("/_1231admin");
        store.commit("set_admin_user", null);
      } else if (
        router.currentRoute.value.path.indexOf("/company-admin") != -1
      ) {
        store.commit("set_company_admin_user", null);
        if (data.company_id != "") {
          window.location.href = "/company-admin/" + data.company_id;
        } else {
          router.push("/company-admin");
        }
      } else if (
        router.currentRoute.value.path.indexOf("/company-user") != -1
      ) {
        router.push("/company-user");
        store.commit("set_company_user", null);
      }
    }
  });
}

function Logout() {
  if (store.state.company_admin_user != null)
    data.company_id = store.state.company_admin_user.company_id;
  userApi.logoutUser().then((res) => {
    if (res.data.error_code == 0) {
      CheckUser();
      // routerSwitch("/admin");
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function langSwitch() {
  cookieSetting.doCookieSetup("lang", store.state.lang);
  i18n.locale.value = store.state.lang;
  location.reload();
}
function routerSwitch(url) {
  router.push(url);
}
const data = reactive({
  company_id: "",
  visibleLeft: false,
  lang: [
    { name: "中文", code: "cn" },
    { name: "English", code: "en" },
    { name: "やまと", code: "jp" },
  ],
  items:
    router.currentRoute.value.path.indexOf("/_1231admin") != -1
      ? [
          {
            label: i18n.$t("AdminNavBar").Company.Label,
            to: i18n.$t("AdminNavBar").Company.Link,
            icon: i18n.$t("AdminNavBar").Company.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").Organisation.Label,
            to: i18n.$t("AdminNavBar").Organisation.Link,
            icon: i18n.$t("AdminNavBar").Organisation.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").Department.Label,
            to: i18n.$t("AdminNavBar").Department.Link,
            icon: i18n.$t("AdminNavBar").Department.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").Position.Label,
            to: i18n.$t("AdminNavBar").Position.Link,
            icon: i18n.$t("AdminNavBar").Position.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").CompanyAdmin.Label,
            to: i18n.$t("AdminNavBar").CompanyAdmin.Link,
            icon: i18n.$t("AdminNavBar").CompanyAdmin.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").CompanyBill.Label,
            to: i18n.$t("AdminNavBar").CompanyBill.Link,
            icon: i18n.$t("AdminNavBar").CompanyBill.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").CompanyLimit.Label,
            to: i18n.$t("AdminNavBar").CompanyLimit.Link,
            icon: i18n.$t("AdminNavBar").CompanyLimit.Logo,
          },
          {
            label: i18n.$t("AdminNavBar").LineBot.Label,
            to: i18n.$t("AdminNavBar").LineBot.Link,
            icon: i18n.$t("AdminNavBar").LineBot.Logo,
          },
          // {
          //   label: i18n.$t("AdminNavBar").Permission.Label,
          //   link: i18n.$t("AdminNavBar").Permission.Link,
          //   logo: i18n.$t("AdminNavBar").Permission.Logo,
          // },
        ]
      : router.currentRoute.value.path.indexOf("/company-admin") != -1
      ? [
          {
            label: i18n.$t("CompanyAdminNavBar").AllPermission.Label,
            icon: i18n.$t("CompanyAdminNavBar").AllPermission.Logo,
            items: [
              {
                label: i18n.$t("CompanyAdminNavBar").Organisation.Label,
                to: i18n.$t("CompanyAdminNavBar").Organisation.Link,
                icon: i18n.$t("CompanyAdminNavBar").Organisation.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Department.Label,
                to: i18n.$t("CompanyAdminNavBar").Department.Link,
                icon: i18n.$t("CompanyAdminNavBar").Department.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Position.Label,
                to: i18n.$t("CompanyAdminNavBar").Position.Link,
                icon: i18n.$t("CompanyAdminNavBar").Position.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Employer.Label,
                to: i18n.$t("CompanyAdminNavBar").Employer.Link,
                icon: i18n.$t("CompanyAdminNavBar").Employer.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").CompanyBill.Label,
                to: i18n.$t("CompanyAdminNavBar").CompanyBill.Link,
                icon: i18n.$t("CompanyAdminNavBar").CompanyBill.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").LineBot.Label,
                to: i18n.$t("CompanyAdminNavBar").LineBot.Link,
                icon: i18n.$t("CompanyAdminNavBar").LineBot.Logo,
              },
            ],
          },
          {
            label: i18n.$t("CompanyAdminNavBar").AllBooking.Label,
            icon: i18n.$t("CompanyAdminNavBar").AllBooking.Logo,
            items: [
              // {
              //   label: i18n.$t("CompanyAdminNavBar").StoreInfo.Label,
              //   to: i18n.$t("CompanyAdminNavBar").StoreInfo.Link,
              //   icon: i18n.$t("CompanyAdminNavBar").StoreInfo.Logo,
              // },
              {
                label: i18n.$t("CompanyAdminNavBar").AppointmentEmployer.Label,
                to: i18n.$t("CompanyAdminNavBar").AppointmentEmployer.Link,
                icon: i18n.$t("CompanyAdminNavBar").AppointmentEmployer.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Service.Label,
                to: i18n.$t("CompanyAdminNavBar").Service.Link,
                icon: i18n.$t("CompanyAdminNavBar").Service.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Shift.Label,
                to: i18n.$t("CompanyAdminNavBar").Shift.Link,
                icon: i18n.$t("CompanyAdminNavBar").Shift.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").EmployerShift.Label,
                to: i18n.$t("CompanyAdminNavBar").EmployerShift.Link,
                icon: i18n.$t("CompanyAdminNavBar").EmployerShift.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Appointment.Label,
                to: i18n.$t("CompanyAdminNavBar").Appointment.Link,
                icon: i18n.$t("CompanyAdminNavBar").Appointment.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").AppointmentLog.Label,
                to: i18n.$t("CompanyAdminNavBar").AppointmentLog.Link,
                icon: i18n.$t("CompanyAdminNavBar").AppointmentLog.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").LineUser.Label,
                to: i18n.$t("CompanyAdminNavBar").LineUser.Link,
                icon: i18n.$t("CompanyAdminNavBar").LineUser.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").Msg.Label,
                to: i18n.$t("CompanyAdminNavBar").Msg.Link,
                icon: i18n.$t("CompanyAdminNavBar").Msg.Logo,
              },
              // {
              //   label: i18n.$t("CompanyAdminNavBar").LineUser.Label,
              //   to: i18n.$t("CompanyAdminNavBar").LineUser.Link,
              //   icon: i18n.$t("CompanyAdminNavBar").LineUser.Logo,
              // },
              // {
              //   label: i18n.$t("CompanyAdminNavBar").Msg.Label,
              //   to: i18n.$t("CompanyAdminNavBar").Msg.Link,
              //   icon: i18n.$t("CompanyAdminNavBar").Msg.Logo,
              // },
            ],
          },
          {
            label: i18n.$t("CompanyAdminNavBar").AllCase.Label,
            icon: i18n.$t("CompanyAdminNavBar").AllCase.Logo,
            items: [
              {
                label: i18n.$t("CompanyAdminNavBar").CaseList.Label,
                to: i18n.$t("CompanyAdminNavBar").CaseList.Link,
                icon: i18n.$t("CompanyAdminNavBar").CaseList.Logo,
              },
              {
                label: i18n.$t("CompanyAdminNavBar").CaseProfitList.Label,
                to: i18n.$t("CompanyAdminNavBar").CaseProfitList.Link,
                icon: i18n.$t("CompanyAdminNavBar").CaseProfitList.Logo,
              },
            ],
          },
        ]
      : router.currentRoute.value.path.indexOf("/company-user") != -1
      ? []
      : [],
});
</script>

<template>
  <Toolbar key="1" class="toolbar">
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        style="background: #1c0166 !important"
        @click="data.visibleLeft = true"
      />
      <img alt="logo" :src="logo" class="p-mr-2 logo p-ml-6" />
    </template>

    <template #end>
      <!-- <Dropdown
        v-model="store.state.lang"
        :options="data.lang"
        @change="langSwitch"
        optionLabel="name"
        optionValue="code"
      /> -->
      <Button
        class="p-button-danger p-ml-1"
        icon="pi pi-sign-out"
        iconPos="left"
        @click="Logout"
      ></Button>
    </template>
  </Toolbar>
  <Toolbar
    key="2"
    class="toolbar_detail p-d-none"
    style="
      padding-top: 8px !important;
      white-space: nowrap;
      overflow: scroll;
      z-index: 100;
    "
  >
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        @click="data.visibleLeft = true"
      />
      <!-- <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('/')"
      >
        {{ i18n.$t("Material").Title }}
      </div> -->
    </template>
  </Toolbar>
  <Sidebar v-model:visible="data.visibleLeft" position="left">
    <PanelMenu :model="data.items" v-model:expandedKeys="expandedKeys" />
    <!-- <div class="p-grid">
      <Button
        :key="index"
        v-for="(item, index) in data.items"
        class="p-button-lg p-button-info p-col-12 p-button-text p-pt-2 p-pb-3"
        :icon="item.logo"
        :label="item.label"
        @click="routerSwitch(item.link)"
      />
    </div> -->
  </Sidebar>
</template>

<style lang="scss">
.button {
  width: auto;
  cursor: pointer;
  border: 2px dotted;
}
.button:hover {
  color: rgb(199, 6, 6);
  border-bottom: 0.5rem solid;
}
.toolbar_detail {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 50px;
  left: 0px;
  /* overflow: hidden; */
  border-top: none !important;
  background: rgb(252, 252, 252) !important;
  border-bottom: 0.1rem solid !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 85px;
  z-index: 0;
}
.toolbar {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 85px;
  left: 0px;
  /* overflow: hidden; */
  background: rgb(28, 1, 102) !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 0;
  z-index: 99;
  .logo {
    position: fixed; /* Set the navbar to fixed position */
    top: 5;
    height: 65px;
  }
}
</style>
